














import * as data from './data/data.json';
import Vue from 'vue';
import $ from 'jquery';
import {mapActions, mapGetters} from 'vuex';
import {GameInfo, StepInfoDict} from '@/types/game';
import Menu from '@/components/Menu.vue';
import {ScreenOrientation} from '@/types/ui';
import ClickEventOverlay from '@/components/ClickEventOverlay.vue';

export default Vue.extend({
    components: {
        Menu,
        ClickEventOverlay,
    },
    created() {
        this.setAudioEnabled(true);
        this.setAudioAvailable(true);

        window.addEventListener('resize', this.handleResize);
        window.addEventListener('orientationchange', this.handleOrientationChange);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('orientationchange', this.handleOrientationChange);
    },
    mounted() {

        //fill stepNo with key of the steps object
        Object.keys(data.steps).forEach((key: string) => {
            (data as unknown as GameInfo).steps[key].stepNo = key;
        })
        this.updateGameData({
            title: data.title,
            steps: data.steps as unknown as StepInfoDict,
            totalSteps: data.totalSteps,
        } as GameInfo);
    },
    computed: {
        ...mapGetters('game', ['enteredApp','audioAvailable']),
    },
    methods: {
        ...mapActions('game', ['updateGameData', 'setEnteredApp', 'setAudioAvailable', 'setAudioEnabled']),

        handleOrientationChange() {
            window.location.reload();
        },
        handleResize() {
            const menuHeight = $('.c-menu').first().height() ?? 0;
            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;
            const ratioScreen = screenWidth / screenHeight;
            const orientation = (ratioScreen === 1) ? ScreenOrientation.SQUARE : (ratioScreen < 1) ? ScreenOrientation.PORTRAIT : ScreenOrientation.LANDSCAPE;
            let targetHeight = screenHeight - menuHeight;
            const $wrapper = $('.c-step__wrapper').first();
            $wrapper.removeClass('portrait');
            $wrapper.removeClass('landscape');
            $wrapper.height('');
            $wrapper.width('');

            if (orientation === ScreenOrientation.PORTRAIT) {
                $wrapper.height(screenHeight - menuHeight);
                $wrapper.addClass('portrait');
            } else {
                $wrapper.addClass('landscape');
                if (screenHeight < 600) {
                    $wrapper.height(screenWidth);
                    $wrapper.width(screenWidth);
                } else {
                    $wrapper.height(targetHeight);
                    $wrapper.width(targetHeight);
                }
            }
        },
    },
});
