






import Vue from 'vue';

export default Vue.extend({
    name: 'Progress',
    props: {
        progress: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        style() {
            return {'width': this.progress + '%'}
        },
    },
});
