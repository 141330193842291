import {ActionContext} from 'vuex';
import {RootState} from '@/store';
import {SubStepInfo} from '@/types/step';

type SubStepState = SubStepInfo;

type SubStepAction = ActionContext<SubStepState, RootState>

const state: SubStepInfo = {
    text: undefined,
    options: undefined,
};

export default {
    namespaced: true,
    state,
    getters: {
        currentSubStepInfo(state: SubStepState): SubStepInfo | undefined {
            return state;
        },
    },
    actions: {
        setCurrentSubStep({commit}: SubStepAction, currentSubStep: SubStepInfo): void {
            commit('setCurrentSubStep', currentSubStep);
        },
    },
    mutations: {
        setCurrentSubStep(state: SubStepState, currentSubStep: SubStepInfo): void {
            Object.assign(state, currentSubStep);
        },
    },
};
