import {ActionContext} from 'vuex';
import {RootState} from '@/store';
import {GameInfo, StepInfoDict} from '@/types/game';
import {StepInfo} from '@/types/step';

type GameState = GameInfo;

type GameAction = ActionContext<GameState, RootState>

const state: GameState = {
    title: '',
    steps: {} as StepInfoDict,
    totalSteps: 0,
    audioEnabled: false,
    enteredApp: false,
    audioAvailable: true, // todo remove?
    gameEnabled: false,
};

export default {
    namespaced: true,
    state,
    getters: {
        title(state: GameState): string {
            return state.title;
        },
        steps(state: GameState): StepInfoDict {
            return state.steps;
        },
        getStep: (state: GameState) => (stepNo: string): StepInfo | undefined => {
            return state.steps[stepNo];
        },
        totalSteps(state: GameState): number {
            return state.totalSteps;
        },
        audioEnabled(state: GameState): boolean {
            return state.audioEnabled;
        },
        enteredApp(state: GameState): boolean {
            return state.enteredApp;
        },
        audioAvailable(state: GameState): boolean {
            return state.audioAvailable;
        },
        gameEnabled(state: GameState): boolean {
            return state.gameEnabled;
        },
    },
    actions: {
        updateGameData({commit}: GameAction, gameInfo: GameInfo): void {
            commit('updateGameData', gameInfo);
        },
        setAudioEnabled({commit}: GameAction, audioEnabled: boolean): void {
            commit('setAudioEnabled', audioEnabled);
        },
        setEnteredApp({commit}: GameAction, enteredApp: boolean): void {
            commit('setEnteredApp', enteredApp);
        },
        setAudioAvailable({commit}: GameAction, audioAvailable: boolean): void {
            commit('setAudioAvailable', audioAvailable);
        },
        setGameEnabled({commit}: GameAction, gameEnabled: boolean): void {
            commit('setGameEnabled', gameEnabled);
        },
    },
    mutations: {
        updateGameData(state: GameState, gameInfo: GameInfo): void {
            Object.assign(state, gameInfo);
        },
        setAudioEnabled(state: GameState, audioEnabled: boolean): void {
            Object.assign(state, {audioEnabled: audioEnabled});
        },
        setEnteredApp(state: GameState, enteredApp: boolean): void {
            Object.assign(state, {enteredApp: enteredApp});
        },
        setAudioAvailable(state: GameState, audioAvailable: boolean): void {
            Object.assign(state, {audioAvailable: audioAvailable});
        },
        setGameEnabled(state: GameState, gameEnabled: boolean): void {
            Object.assign(state, {gameEnabled: gameEnabled});
        },
    },
};
