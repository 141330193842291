import Vue from 'vue';
import Vuex from 'vuex';

import CurrentSubStepModule from './currentSubStep';
import CurrentStepModule from './currentStep';
import GameModule from './game';

Vue.use(Vuex);

export interface RootState { [n: string]: never }

export default new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    modules: {
        currentSubStep: CurrentSubStepModule,
        currentStep: CurrentStepModule,
        game: GameModule,
    },
});
