import {ActionContext} from 'vuex';
import {RootState} from '@/store';
import {CurrentStepInfo, StepInfo} from '@/types/step';

type StepState = CurrentStepInfo;

type StepAction = ActionContext<StepState, RootState>

const state: CurrentStepInfo = {
    stepNo: '',
    prevStepNo: '',
    stepIndex: 0, stepProgress: 0, totalSteps: 0,
};

export default {
    namespaced: true,
    state,
    getters: {
        getPrevStepNo(state: StepState): string {
            return state.prevStepNo;
        },
        currentStepInfo(state: StepState): CurrentStepInfo {
            return state;
        },
    },
    actions: {
        setCurrentStep({commit, rootGetters}: StepAction, currentStep: CurrentStepInfo): void {
            currentStep.stepIndex = (rootGetters['game/getStep'](currentStep.stepNo) as StepInfo)?.stepIndex ?? 0;
            currentStep.stepProgress = Math.round(currentStep.stepIndex / (rootGetters['game/totalSteps'] ?? 0) * 100);

            commit('setCurrentStep', currentStep);
        },
    },
    mutations: {
        setCurrentStep(state: StepState, currentStep: CurrentStepInfo): void {
            Object.assign(state, currentStep);
        },
    },
};
