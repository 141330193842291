












import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';

export default Vue.extend({
    name: 'ClickEventOverlay',

    computed: {
        ...mapGetters('game', ['enteredApp', 'audioAvailable', 'gameEnabled']),
    },
    methods: {
        ...mapActions('game', ['setEnteredApp']),

        startStep() {
            if (this.gameEnabled) {
                this.setEnteredApp(true)
            }
        },
    },
});
