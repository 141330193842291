























































import Vue from 'vue';
import {mapGetters} from 'vuex';
import Progress from '@/components/Progress.vue';
import ToggleAudio from '@/components/ToggleAudio.vue';
import SocialShare from '@/components/SocialShare.vue';

interface Data {
    menuVisible: boolean;
}

export default Vue.extend({
    name: 'TopMenu',
    components: {
        Progress,
        ToggleAudio,
        SocialShare,
    },
    data(): Data {
        return {
            menuVisible: false,
        }
    },
    computed: {
        ...mapGetters('currentStep', ['currentStepInfo']),
        ...mapGetters('game', ['audioAvailable']),

        menuModifierClasses(): Record<string, boolean> {
            return {
                'fa-bars': !this.menuVisible,
                'fa-times': this.menuVisible,
            };
        },
    },
    methods: {
        toggleMenu() {
            this.menuVisible = !this.menuVisible;
        },
        restartGame() {
            this.toggleMenu();
            /* eslint-disable  @typescript-eslint/no-explicit-any */
            (this.$parent.$refs.refStep as any).routerNavigateNext('start', true);
        },
    },
});
