import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
// import {LoginProgressStep} from '@/types/login';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: '/stap/:stepNo',
        name: 'step',
        props: true,
        component: () => import(/* webpackPrefetch: true, webpackChunkName: "Screen" */ '../views/Step.vue'),
    },
    {
        // redirect all unknown routes to the start page
        path :'*',
        props: true,
        redirect: { name: 'step', params: { 'stepNo': 'start'}},
    }];

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
});

export default router;

