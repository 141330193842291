import Vue from 'vue';
import VueLogger from 'vuejs-logger';
import {ILoggerOptions} from 'vuejs-logger/dist/interfaces/logger-options';
import {LogLevels} from 'vuejs-logger/dist/enum/log-levels';
import './scss/main.scss';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import VueAnalytics from 'vue-analytics';

const isProduction = process.env.NODE_ENV === 'production';

const options: ILoggerOptions = {
    isEnabled: true,
    logLevel: isProduction ? LogLevels.ERROR : LogLevels.DEBUG,
    stringifyArguments: false,
    showLogLevel: true,
    showMethodName: true,
    separator: '|',
    showConsoleColors: true,
};

require('jquery');

// @ts-ignore
Vue.use(VueLogger, options);

// Disable the standard ProductionTip warning
Vue.config.productionTip = false;
// Disable devtools && devtools log warning
Vue.config.devtools = !isProduction;

// see also https://medium.com/dailyjs/tips-tricks-for-vue-analytics-87a9d2838915
Vue.use(VueAnalytics, {
    id: 'UA-185024848-1',
    router,
    set: [
        { field: 'anonymizeIp', value: true },
    ],
    debug: {
        // enabled: !isProduction,
        sendHitTask: isProduction,
    },
})

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
