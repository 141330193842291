




import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';

export default Vue.extend({
    name: 'Progress',
    props: {
        progress: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        ...mapGetters('game', ['audioEnabled']),

        modifierClasses(): Record<string, boolean> {
            return {
                'fas': true,
                'fa-volume': this.audioEnabled,
                'fa-volume-mute': !this.audioEnabled,
            };
        },
    },
    methods: {
        ...mapActions('game', ['setAudioEnabled']),

        toggleAudio() {
            this.setAudioEnabled(!this.audioEnabled);
        },
    },
});
