export enum ScreenRatio {
    FIT,
    TOO_WIDE,
    TOO_HIGH,
}

export enum ScreenOrientation {
    SQUARE,
    PORTRAIT,
    LANDSCAPE,
}
